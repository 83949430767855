import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Modal, setFieldTouchedMutator } from '@watched-tech/wantent-ui';

import { useMutation } from 'react-query';
import { panelRespondentLogin } from 'Features/participant/register/queries/mutations';
import { useNavigate } from 'react-router-dom';
import { REGISTRATION_MODAL_WIDTH } from 'Features/participant/register/constants';
import { DemographicsAndTOSForm } from 'Features/participant/register/components/shared/DemographicsAndTOSForm';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';
import { removeAuthToken, signInWntUserWithTokens } from 'Shared/auth/schemes/wnt';
import { useAuthContext } from 'Shared/auth/context';

interface IFormProps {
    panelGroupId: string;
    panelRespondentId: string;
}

function RegisterPanelGroupForm(props: IFormProps) {
    const { panelRespondentId, panelGroupId } = props;
    const { t: getLabel } = useTranslation();
    const navigate = useNavigate();
    const showMobileView = useSelector(getMobileView);
    const auth = useAuthContext();

    const { mutate, isLoading } = useMutation(panelRespondentLogin, {
        onSuccess: (tokens) => {
            const redirectUrl = showMobileView ? '/me' : '/survey-disclaimer';

            signInWntUserWithTokens(tokens.accessToken, tokens.refreshToken, auth);
            auth.updateScheme('wnt');
            navigate(redirectUrl);
        },
    });
    const handleRegisterFormSubmit = useCallback(
        (values: any) => {
            const { panelGroupId, panelRespondentId, age, sex } = values;
            removeAuthToken();
            const res = mutate({
                body: {
                    age,
                    sex,
                    panelGroupId,
                    panelRespondentId,
                },
            });
        },
        [mutate],
    );

    const renderForm = useCallback(
        ({ handleSubmit, invalid, form, errors, values }) => {
            return (
                <DemographicsAndTOSForm
                    onSubmit={handleSubmit}
                    form={form}
                    invalid={invalid}
                    errors={errors}
                    values={values}
                    isLoading={isLoading}
                />
            );
        },
        [isLoading],
    );
    return (
        <Modal
            open
            onClose={() => {}}
            title={getLabel('register.heading')}
            // subTitle={getLabel('register.subtitle')}
            width={REGISTRATION_MODAL_WIDTH}
            withCloseIcon={false}
            scroll="body"
            sx={{
                modal: {
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent',
                        backdropFilter: 'none',
                    },
                },
            }}
            dataAttrs={{
                'data-testid': 'pg-registration',
            }}
        >
            <Form
                onSubmit={handleRegisterFormSubmit}
                mutators={{ setFieldTouched: setFieldTouchedMutator }}
                initialValues={{
                    panelGroupId,
                    panelRespondentId,
                }}
            >
                {renderForm}
            </Form>
        </Modal>
    );
}

export default RegisterPanelGroupForm;
