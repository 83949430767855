import { UiLocale } from 'Src/localizations/i18n';

export enum DataCollectionMethodType {
    focusGroup = 'focusGroup',
    tiburonPanelGroup = 'tiburonPanelGroup',
    cintPanelGroup = 'cintPanelGroup',
    openGroup = 'openGroup',
    templateUrlPanelGroup = 'templateUrlPanelGroup',
}

export interface IDataCollectionMethod {
    id?: string;
    preferredLocale: UiLocale;
    title: string;
    type: DataCollectionMethodType;
}

export interface IFocusGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.focusGroup;
    assignedFocusGroupId: string;
}
export interface IPanelGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.tiburonPanelGroup | DataCollectionMethodType.cintPanelGroup;
    preferredLocale: UiLocale;
}
export interface IOpenGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.openGroup;
    participantCount: number;
}

export type DataCollectionMethod = IFocusGroupDcm | IPanelGroupDcm | IOpenGroupDcm;
