import { VideoType } from 'Features/manager/shared/types/videos/VideoType';
import { UiLocale } from 'Src/localizations/i18n';
import { DataCollectionMethodType } from './DataCollectionMethod';
import { SurveyElement } from 'Shared/types';
import { Quota } from 'src/pages/manager/scenarios/ManagerEditQuotas';
export interface IManagerScenario {
    id: string;
    name: string;
    steps: IManagerScenarioStep[];
    createdAt: Date;
}

interface IManagerScenarioStep {
    id: string;
    name: string;
    stepPosition: number;
}
export interface IManagerScenarioDetailsDCM {
    id: string;
    title: string;
    preferredLocale: UiLocale;
    type: DataCollectionMethodType;
}

export interface IManagerScenarioDetailsFocusGroup extends IManagerScenarioDetailsDCM {
    participantCount: number;
    type: DataCollectionMethodType.focusGroup;
}
export interface IManagerScenarioDetailsPanelGroup extends IManagerScenarioDetailsDCM {
    joinLink: string;
    type:
        | DataCollectionMethodType.cintPanelGroup
        | DataCollectionMethodType.tiburonPanelGroup
        | DataCollectionMethodType.templateUrlPanelGroup;
}

export interface IManagerScenarioDetailsOpenGroup extends IManagerScenarioDetailsDCM {
    joinLink: string;
    type: DataCollectionMethodType.openGroup;
}
export type ManagerScenarioDetailsDcm =
    | IManagerScenarioDetailsFocusGroup
    | IManagerScenarioDetailsPanelGroup
    | IManagerScenarioDetailsOpenGroup;

type IManagerScenarioDetailsStepType = 'video' | 'presentation' | 'landing' | 'survey' | 'website';

export interface IManagerScenarioDetailsStep {
    id: string;
    name: string;
    stepPosition: number;
    hasAnyAvailableData: boolean;
    stepType: IManagerScenarioDetailsStepType;
}

export interface ScenarioAudienceBucket {
    id: string;
    name: string;
    description: string;
    participantCount: number;
}

export interface AudienceBucketDetails extends ScenarioAudienceBucket {
    quotas: Quota[];
    dataCollectionMethods: ManagerScenarioDetailsDcm[];
}

export interface IManagerScenarioDetails {
    id: string;
    name: string;
    nameForParticipants: string;
    steps: IManagerScenarioDetailsStep[];
    dataCollectionMethods: ManagerScenarioDetailsDcm[];
    audienceBuckets: ScenarioAudienceBucket[];
}

export interface IAIReportDetails {
    createdAt: string;
    status: 'starting' | 'loading' | 'done' | 'error';
    downloadUrl?: string;
}

export interface ISurveyDetails {
    title: string;
    surveyElements: SurveyElementDetails[];
}

type SurveyElementDetails = SurveyElement;
export enum RecordingState {
    waitingForRecording = 'waitingForRecording',
    recording = 'recording',
    finished = 'finished',

    transcoding = 'transcoding',
    transcoded = 'transcoded',

    recognizing = 'recognizing',
    recognized = 'recognized',
}
export interface IManagerScenarioDetailsSessionStatus {
    id: string;
    isOk: boolean;
    name: string;
    recordingState: RecordingState;
}

export enum ManagerScenarioDetailsState {
    started = 'started',
    leaveInitiated = 'leaveInitiated',
    screenOut = 'screenOut',
    finished = 'finished',
}

export interface IManagerScenarioDetailsSession {
    id: string;
    createdAt: string;
    stepSessionStatuses: IManagerScenarioDetailsSessionStatus[];
    stepSessionsCount: number;
    state: ManagerScenarioDetailsState;
}

export interface IManagerScenarioDetailsStatus {
    overallStatus: IManagerScenarioReactionRecordingsStatus;
    stepStatuses: IManagerScenarioStepReactionRecordingsStatus[];
    participantStatuses: IManagerScenarioDetailsParticipantStatus[];
}

export interface IManagerScenarioReactionRecordingsStatus {
    invalidRecordingStepSessionsCount: number;
    reactionStepSessionsCount: number;
    recognizedStepSessionsCount: number;
}
export interface IManagerScenarioStepReactionRecordingsStatus extends IManagerScenarioReactionRecordingsStatus {
    name: string;
    id: string;
    stepPosition: number;
}

export interface IStatusDataCollectionMethod {
    id: string;
    title: string;
}

export interface IManagerScenarioDetailsParticipantStatus {
    id: string;
    email: string;
    dataCollectionMethod: IStatusDataCollectionMethod;
    scenarioSessions: IManagerScenarioDetailsSession[];
}

export enum VideoUploadStatus {
    inactive = 'inactive',
    uploading = 'uploading',
    done = 'done',
}

export interface IVideoUpload {
    percentsDone: number;
    status: VideoUploadStatus;
    id: string;
    name: string;
}

interface IVideoReactionSource {
    videoType: VideoType;
    name: string;
    edl?: File;
}
export interface IYoutubeReactionSource extends IVideoReactionSource {
    link: string;
}
export interface ICustomWatermarkedVideoReactionSource extends IVideoReactionSource {
    duration: string;
    file: File;
}

export type VideoReactionSource = ICustomWatermarkedVideoReactionSource | IYoutubeReactionSource;
