import { IScenarioResponse } from 'Features/participant/workspace/scenario/types';
import { IVideoLinkParams, IVideoLinkObj } from 'Features/participant/workspace/scenario/types';
import {
    IParticipantCalibrationIds,
    IParticipantCalibratopnStepResponse,
} from 'Features/participant/workspace/scenario/types';
import { fromScenarioResponse } from 'Features/participant/workspace/scenario/queries/maps';
import { UseQueryOptions } from 'react-query/types/react/types';
import { useAuthenticatedQuery } from 'Src/shared/hooks/queries';

export const participantQueries = {
    useParticipantScenario: (id: string, options: UseQueryOptions<IScenarioResponse>) =>
        useAuthenticatedQuery<IScenarioResponse>(
            ['participant.getScenario', { id }],
            {
                url: (params: any) => `/api/me/scenarios/${params.queryKey[1]?.id}`,
                dataMapper: fromScenarioResponse,
            },
            options,
        ),
    useParticipantSurvey: (id: string, options: UseQueryOptions<any>) =>
        useAuthenticatedQuery<any>(
            ['participant.getSurvey', { id }],
            {
                url: (params: any) => `/api/me/surveys/${params.queryKey[1]?.id}`,
            },
            options,
        ),
    useCustomVideoLinkObj: (params: IVideoLinkParams, options: UseQueryOptions<IVideoLinkObj>) =>
        useAuthenticatedQuery<IVideoLinkObj>(
            ['participant.getCustomVideoLinkObj', params],
            {
                url: (queryParams: any) =>
                    `${queryParams.queryKey[1]?.uri}/${queryParams.queryKey[1]?.videoForReactionId}?stepSessionId=${queryParams.queryKey[1]?.stepSessionId}`,
            },
            options,
        ),
    useYoutubeVideoLinkObj: (videoForReactionId: string, options: UseQueryOptions<IVideoLinkObj>) =>
        useAuthenticatedQuery<IVideoLinkObj>(
            ['participant.getYoutubeVideoLinkObj', { videoForReactionId }],
            {
                url: (params: any) => `/api/me/videos/youtube/${params.queryKey[1]?.videoForReactionId}`,
            },
            options,
        ),
    useParticipantScenarioCalibrationStep: (
        params: IParticipantCalibrationIds,
        options: UseQueryOptions<IParticipantCalibratopnStepResponse>,
    ) =>
        useAuthenticatedQuery<IParticipantCalibratopnStepResponse>(
            ['participant.getCalibrationStep', params],
            {
                url: (queryParams: any) =>
                    `/api/me/scenarios/${queryParams.queryKey[1]?.scenarioId}/calibration_steps/${queryParams.queryKey[1]?.calibrationId}`,
            },
            options,
        ),
};
